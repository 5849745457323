import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaderService } from './http-header.service';
import { TransactionType } from '../model/transaction-type.model'; // Assuming you have defined TransactionType and Params models

@Injectable({
  providedIn: 'root'
})
export class TransactionTypeService {

  constructor(
    private http: HttpClient,
    private httpHeaderService: HttpHeaderService
  ) {}

  transactionType = new TransactionType();
  transactionTypeList: Array<TransactionType> = [];

 

  getAll(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiurl}transactionType/getAll`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  create(data: Partial<TransactionType>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiurl}transactionType/create`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(false);
          }
        );
    });
  }

  update(id: number, data: Partial<TransactionType>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${environment.apiurl}transactionType/update/${id}`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(`${environment.apiurl}transactionType/delete/${id}`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
