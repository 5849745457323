import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaderService } from './http-header.service';
import { Services } from '../model/service.model';
@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(
    private http: HttpClient,
    private httpHeaderService: HttpHeaderService
  ) {}

 services = new Services();
 servicesList: Array<Services> = [];


  getAll(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiurl}service/getAll`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error); 
          }
        );
    });
  }

  create(data: Partial<Services>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiurl}service/create`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  update(id: number, data: Partial<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${environment.apiurl}service/update/${id}`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(`${environment.apiurl}service/delete/${id}`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

}
