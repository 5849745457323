export class User {
id: number;
name: string;
email: string;
mobile_number: string;
user_name: string;
password: string;
company_name: string;
city_name: string;
pin_code: string;
address: string;
type_id: number;
type_name: string;
package_id: number;
parent_id: number;
balance: any;
opening_balance: any = 0;
created_date: Date;
is_active: number;
temp:any = {allow_gen_api_token: false};
}
 


export class TotalCount {
    success_amount?: number = 0
    success_count?: number = 0
    failure_amount?: number = 0
    failure_count?: number = 0
     pending_amount?: number = 0
    pending_count?: number = 0
     refund_amount?: number = 0
    refund_count?: number = 0 
}