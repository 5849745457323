import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserTypeService } from "src/app/shared/services/user-type.service";
import { UserService } from "src/app/shared/services/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
const Swal = require("sweetalert2");

export class UserPassword {
  oldPassword: string
  newPassword: string
   userId: number

}
@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  userPassword = new UserPassword();
  constructor(public router: Router, public userTypeService: UserTypeService,  private modalService: NgbModal, public userService: UserService ){
    if (JSON.parse(localStorage.getItem("user"))) {
    } else {
    }
  }

  ngOnInit() {}


  logoutFunc() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    this.router.navigateByUrl('auth/login');
  }

  editProfile(content){
    this.modalService.open(content, { size: "lg" });
  }
  updateProfile(){
    this.userService
    .update(this.userService.loggedInUser.id, this.userService.loggedInUser)
    .then((res) => {
      this.modalService.dismissAll();
 
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: res.message,
        showConfirmButton: false,
        timer: 2500
      })
    })
    .catch((error) => {
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: error.error.message,
        showConfirmButton: false,
        timer: 2500
      })
    });

  }


  changePassword(content){
    this.modalService.open(content, { size: "sm" });
  }

  updatePassword(){
   this.userPassword.userId = this.userService.loggedInUser.id ;
    this.userService
    .changePasswordByCurrentPassword(this.userPassword)
    .then((res) => {
      this.modalService.dismissAll();
    
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: res.message,
        showConfirmButton: false,
        timer: 2500
      })
     this.userPassword = new UserPassword();
    })
    .catch((error) => {
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: error.error.message,
        showConfirmButton: false,
        timer: 2500
      })
    });
  }
}
 