<div class="media profile-media">
  <img class="b-r-10" style="width: 35px;" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2H46pY1PoK1u2R7Cu42Md7F6aC9MMdXYokj9CP7vTcA&s" alt="">
  <div class="media-body"><span>{{userService.loggedInUser.name}}</span>
    <p class="mb-0 font-roboto">{{userTypeService.getUserTypeName(userService.loggedInUser.type_id)}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li> -->
  <!-- <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
  <li><a href="/faq"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li> -->
 <li (click)="editProfile(editprofile)"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Profile </span></li>  

<li (click)="changePassword(changepassword)"><span>Change Password </span></li> 

  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>


<ng-template #changepassword let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Change Password</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form #form="ngForm">
      <div class="row">
      
        <div class="col-sm-12">
          <div class="mb-3">
            <label for="exampleFormControlInput15">Current Password </label>
            <input name="oldPassword" [(ngModel)]="userPassword.oldPassword" required
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        <div class="col-sm-12">
          <div class="mb-3">
            <label for="exampleFormControlInput15">New Password </label>
            <input name="newPassword" [(ngModel)]="userPassword.newPassword" required
              pattern="^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$" class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button [disabled]="!form.valid" (click)="updatePassword()" type="button" class="btn btn-primary">Change Password</button>
    <!-- <button type="reset" class="btn btn-outline-dark" (click)="commonService.resetForm(form)">Reset</button> -->
  </div>
</ng-template>


<ng-template #editprofile let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">My Profile</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form #form="ngForm">
      <div class="row">
      
        <div class="col-sm-4">
          <div class="mb-3">
            <label for="exampleFormControlInput15">Name </label>
            <input name="name" [(ngModel)]="userService.loggedInUser.name" required
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mb-3">
            <label for="exampleFormControlInput15">Username </label>
            <input name="user_name" [(ngModel)]="userService.loggedInUser.user_name" required
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        
        <div class="col-sm-4">
          <div class="mb-3">
            <label for="exampleFormControlInput15">Mobile number </label>
            <input name="mobile_number" [(ngModel)]="userService.loggedInUser.mobile_number" required
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mb-3">
            <label for="exampleFormControlInput15">Email </label>
            <input name="email" [(ngModel)]="userService.loggedInUser.email" required
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        
        <div class="col-sm-4">
          <div class="mb-3">
            <label for="exampleFormControlInput15">Company name </label>
            <input name="company_name" [(ngModel)]="userService.loggedInUser.company_name"
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        

        <div class="col-sm-4">
          <div class="mb-3">
            <label for="exampleFormControlInput15">City </label>
            <input name="city_name" [(ngModel)]="userService.loggedInUser.city_name"
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        
        <div class="col-sm-3">
          <div class="mb-3">
            <label for="exampleFormControlInput15">Pin Code </label>
            <input name="pin_code" [(ngModel)]="userService.loggedInUser.pin_code"
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        
        
        <div class="col-sm-9">
          <div class="mb-3">
            <label for="exampleFormControlInput15">Address </label>
            <input name="address" [(ngModel)]="userService.loggedInUser.address"
              class="form-control " id="exampleFormControlInput15"
              type="text" placeholder="" />
          </div>
        </div>
        
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button [disabled]="!form.valid" (click)="updateProfile()" type="button" class="btn btn-primary">Update Profile</button>
    <!-- <button type="reset" class="btn btn-outline-dark" (click)="commonService.resetForm(form)">Reset</button> -->
  </div>
</ng-template>