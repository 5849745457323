export class Operator{
    id: number;
    name: string;
     code: string;
     sr_no: number
     icon: string;
     router_link: string;
     service_id: number
     api_id_priority: any
     is_active: number = 1 ;

     ext_api_1_id: string;
     ext_api_2_id: string;
     ext_api_3_id: string;
     ext_api_4_id: string;
     ext_recharge_api_ids: string;
}