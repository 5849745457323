import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaderService } from './http-header.service';
import { UserType } from '../model/user-type.model';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {

  constructor(
    private http: HttpClient,
    private httpHeaderService: HttpHeaderService
  ) {}

  userType = new UserType();
  userTypeList: Array<UserType> = [];


  public getUserTypeName(id: any): string {
    const t = this.userTypeList.find(item => item.id == id);
    return t ? t.name : '';
  } 

  getAll(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiurl}userType/getAll`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error); 
          }
        );
    });
  }

  create(data: Partial<UserType>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiurl}userType/create`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(false);
          }
        );
    });
  }

  update(id: number, data: Partial<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${environment.apiurl}userType/update/${id}`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(`${environment.apiurl}userType/delete/${id}`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
