// internet-connection.service.ts

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CommonService } from './common.service';
import { ExtRechargeApiService } from './ext-recharge-api.service';
import { OperatorService } from './operator.service';
import { ServicesService } from './services.service';
import { TransactionTypeService } from './transactionType.service';
import { UserTypeService } from './user-type.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {
  private connectionRestoredSource = new Subject<void>();

  constructor(private router: Router, private userService: UserService, private commonService: CommonService, private userTypeService: UserTypeService, private transactionTypeService: TransactionTypeService, private extRechargeApiService: ExtRechargeApiService, private operatorService: OperatorService, private servicesService: ServicesService, ){

    window.addEventListener('online', async () => {
      this.router.navigateByUrl('/admin/admin-dashboard');
      await  this.commonService.loadUserMasterData().then((res) => {
        this.userTypeService.userTypeList = res.result.userTypes
        this.servicesService.servicesList = res.result.services
        this.operatorService.operatorList = res.result.operator
        this.extRechargeApiService.extRechargeApiList = res.result.extRechargeApi
      this.transactionTypeService.transactionTypeList = res.result.transType

       })
       .catch((error) => {
       });
      
       await  this.userService.getLoggedInUserDetails().then((res) => {
         this.userService.loggedInUser = res.result;
       })
       .catch((error) => {
       });
      this.connectionRestoredSource.next();
    });

    window.addEventListener('offline', () => {
      this.router.navigateByUrl('/error-page/error-404');
    });
  }

  getConnectionRestoredEvent(): Observable<void> {
    return this.connectionRestoredSource.asObservable();
  }
}
