import { Injectable } from '@angular/core';
import { Status} from '../model/status.model';
@Injectable({
  providedIn: 'root'
})
export class StatusService {

    status = new Status();

    statusList: Array<Status> = [];

    getStatusDetailsByCode(code: string){
        return this.statusList.find(a=>a.code == code)
    }
}