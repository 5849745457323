import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaderService } from './http-header.service';
import { Operator } from '../model/operator.model'
import { catchError, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  constructor(
    private http: HttpClient,
    private httpHeaderService: HttpHeaderService
  ) {}

  // Assuming you have an Operator model
  operator = new Operator();
  operatorList: Array<Operator> = [];

  getAll(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiurl}operator/getAll`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error); 
          }
        );
    });
  }
  
 
  // create_(data: Partial<Operator>): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     this.http.post<any>(`${environment.apiurl}operator/create`, data, { headers: this.httpHeaderService.getHeaders() })
  //       .subscribe(
  //         (response: any) => {
  //           resolve(response);
  //         },
  //         (error) => {
  //           reject(false);
  //         }
  //       );
  //   });
  // }
  create(data: Partial<Operator>): Promise<any> {
    return this.http.post<any>(`${environment.apiurl}operator/create`, data, { headers: this.httpHeaderService.getHeaders() })
      .pipe(
        catchError(error => {
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  update(id: number, data: Partial<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${environment.apiurl}operator/update/${id}`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  } 
  updateOperatorSettings(id: number, data: Partial<any>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${environment.apiurl}operator/updateOperatorSettings/${id}`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(`${environment.apiurl}operator/delete/${id}`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
