// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let apiurl = 'https://77-37-45-160.cprapid.com:3355/';
//let apiurl = 'http://localhost:3355/';
let image_api_url = 'https://77-37-45-160.cprapid.com:3800/';
export const environment = {
  production: true,
  apiurl: apiurl,
  image_api_url: image_api_url
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
