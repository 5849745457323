import { Component, PLATFORM_ID, Inject } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
// import { TranslateService } from '@ngx-translate/core';
import {CommonService } from './shared/services/common.service';
import { UserService } from './shared/services/user.service';
import { ServicesService } from './shared/services/services.service';
import { OperatorService } from './shared/services/operator.service';
import { UserTypeService } from './shared/services/user-type.service';
import { ExtRechargeApiService } from './shared/services/ext-recharge-api.service';
import { TransactionTypeService} from './shared/services/transactionType.service';
import { StatusService } from './shared/services/status.service';
import { InternetConnectionService } from './shared/services/internet-connection.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showConnectionMessage: boolean = false;

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private internetConnectionService: InternetConnectionService,
    private loader: LoadingBarService, private extRechargeApiService: ExtRechargeApiService, private transactionTypeService: TransactionTypeService, private commonService: CommonService, 
    private userTypeService: UserTypeService, private operatorService: OperatorService, private statusService: StatusService, private servicesService: ServicesService, 
     private userService: UserService) {
    // if (isPlatformBrowser(this.platformId)) {
    //   translate.setDefaultLang('en');
    //   translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    // }
    if(localStorage.getItem('token')){
      this.commonService.loadUserMasterData().then((res) => {
        userTypeService.userTypeList = res.result.userTypes
        servicesService.servicesList = res.result.services
         operatorService.operatorList = res.result.operator
         extRechargeApiService.extRechargeApiList = res.result.extRechargeApi
         transactionTypeService.transactionTypeList = res.result.transType
         transactionTypeService.transactionTypeList = res.result.transType
         statusService.statusList = res.result.statuses

        //  make sure add same code in login page also
      })
      .catch((error) => {
      });

      this.userService.getLoggedInUserDetails().then((res) => {
         
        userService.loggedInUser = res.result;
      })
      .catch((error) => {
      });
    }
   
    this.commonService.getIPAddress().then(ip => {
      this.commonService.my_ip = ip;
    });
    
  
 
    
  }

  ngOnInit(): void {
   
  
    this.internetConnectionService.getConnectionRestoredEvent().subscribe(() => {
      this.showConnectionMessage = true;
      setTimeout(() => {
        this.showConnectionMessage = false;
      }, 5000); // Hide message after 5 seconds
    });
  }


}
