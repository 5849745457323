import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
 
import { HttpHeaderService } from "../services/http-header.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { DateRange } from '../model/common.model'
@Injectable({
  providedIn: "root",
})
export class CommonService{
 my_ip: string;

  constructor(
    private http: HttpClient,
    private httpHeaderService: HttpHeaderService, private router: Router
  ) {}


  async getIPAddress(): Promise<string> {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return 'Unknown';
    }
  }
  
  loadUserMasterData(): Promise<any> { 
    return new Promise<any[]>((resolve, reject) => {
      this.http
        .get<any>(`${environment.apiurl}common/loadUserMasterData`, {
          headers: this.httpHeaderService.getHeaders(),
        })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            this.handleError(error);
            reject(error);
          }
        );
    });
  }
  
 
  public handleError(error: HttpErrorResponse) {
    if (error.error.error == "TokenExpired") {
      this.handleTokenExpired();
    } if (error.error.error == "InvalidToken") {
      this.handleTokenExpired();
    }
    // Add more error handling logic as needed
  }

  public handleTokenExpired() {
    console.log('Token expired. Redirecting to login page.');
    // Add logic to clear user session, show message, etc.
    this.router.navigateByUrl('auth/login');
  }
 
  // Add more methods for refreshing token, clearing user session, etc.

  resetForm(form: any) {
    form.resetForm();
  }
 

  getDefaultDateRange(daysBefore: number, daysAfter: number) {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 10 days ago
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - daysBefore);

    // Calculate the date 10 days from now
    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + daysAfter);

    // Convert the dates to the desired format (yyyy-MM-dd)
    const formattedStartDate = this.transformDate(startDate, 'yyyy-MM-dd');
    const formattedEndDate = this.transformDate(endDate, 'yyyy-MM-dd');

    return {
        startDate: formattedStartDate,
        endDate: formattedEndDate
    };
}

transformDate(value: any, transformation: string, args?: any): any {
  const datePipe = new DatePipe('en-US');
  return datePipe.transform(value, transformation);
}
formateDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
getOnlyDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

dateRangeList: Array<DateRange> = [
 
  {
    id: 1,
    name: 'Today',
    start_date: this.getYearMonthDay(new Date()),
    end_date: this.getYearMonthDay(new Date()),
    is_show: true,
    is_default: false,
    code: 'today',
  },
  {
    id: 2,
    name: 'Yesterday',
    start_date: this.getYearMonthDay(new Date(new Date().setDate(new Date().getDate() - 1))),
    end_date: this.getYearMonthDay(new Date(new Date().setDate(new Date().getDate() - 1))),
    is_show: true,
    is_default: false,
    code: 'yesterday',
  },
  {
    id: 3,
    name: 'This week',
    start_date: this.getYearMonthDay(this.getStartOfWeek(new Date())),
    end_date: this.getYearMonthDay(this.getEndOfWeek(new Date())),
    is_show: true,
    is_default: true,
    code: 'thisweek',
  },
  {
    id: 4,
    name: 'This month',
    start_date: this.getYearMonthDay(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    end_date: this.getYearMonthDay(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)),
    is_show: true,
    is_default: false,
    code: 'thismonth',
  },
  {
    id: 5,
    name: 'This Year',
    start_date: this.getYearMonthDay(new Date(new Date().getFullYear(), 0, 1)),
    end_date: this.getYearMonthDay(new Date(new Date().getFullYear(), 11, 31)),
    is_show: true,
    is_default: false,
    code: 'thisyear',
  },

];

getYearMonthDay(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}


getStartOfWeek(date: Date): Date {
  const day = date.getDay();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - day);
}

getEndOfWeek(date: Date): Date {
  const day = date.getDay();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + (6 - day));
}
}
