import { Routes } from "@angular/router";

export const content: Routes = [
 
  {
    path: "admin",
    loadChildren: () => import("../../components/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "api-user",
    loadChildren: () => import("../../components/api-user/api-user.module").then((m) => m.ApiUserModule),
  },
  {
    path: "sd-user",
    loadChildren: () => import("../../components/sd-user/sd-user.module").then((m) => m.SdUserModule),
  },
  
];
