export class Services {
  id: number;
  name: string;
  sr_no: number;
  icon: string;
  is_operators: number = 1;
  router_link: string;
  is_active: number = 1;
  is_show_dashboard: number = 1;
}
 