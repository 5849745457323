import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaderService } from './http-header.service';
import { ExtRechargeApi } from '../model/ext-recharge-api.model'; // Assuming you have defined ExtRechargeApi and Params models

@Injectable({
  providedIn: 'root'
})
export class ExtRechargeApiService {

  constructor(
    private http: HttpClient,
    private httpHeaderService: HttpHeaderService
  ) {}

  extRechargeApi = new ExtRechargeApi();
  extRechargeApiList: Array<ExtRechargeApi> = [];


// code to get ext api res
  getRealroboOperatorBalance(body): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`https://realrobo.in/api/operator_balance`, body)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  } 
  
// end


  getAll(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${environment.apiurl}extRechargeApi/getAll`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  create(data: Partial<ExtRechargeApi>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(`${environment.apiurl}extRechargeApi/create`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(false);
          }
        );
    });
  }

  update(id: number, data: Partial<ExtRechargeApi>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(`${environment.apiurl}extRechargeApi/update/${id}`, data, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(`${environment.apiurl}extRechargeApi/delete/${id}`, { headers: this.httpHeaderService.getHeaders() })
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
