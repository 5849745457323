import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  ADMIN_MENUITEMS: Menu[] = [
    {
      path: "/admin/admin-dashboard",
      title: "Dashboard",
      icon: "home",
      type: "link",
    },
    {
      title: "Android App",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/admin/android-app/banner", title: "Banners", type: "link" },
      ],
    },
    {
      title: "Wallet",
      icon: "bonus-kit",
      type: "sub",
      active: false,
      children: [
        { path: "/admin/wallet", title: " Add Balance", type: "link" },
      ],
    },

    {
      title: "Masters",
      icon: "widget",
      type: "sub",
      active: false,
      children: [
        { path: "/admin/services", title: "Services", type: "link" },
        { path: "/admin/operator", title: "Operators", type: "link" },
        // { path: "/admin/bank", title: "My Banks", type: "link" },
        // { path: "/admin/upi-platform", title: "UPI Platforms", type: "link" },
        // { path: "/admin/upi", title: "My UPI", type: "link" },
      { path: "/admin/my-recharge-api", title: "My Recharge API", type: "link" },
      ],
    },
    
    {
      title: "External API",
      icon: "learning",
      type: "sub",
      active: false,
      children: [
        { path: "/admin/ext-api/ext-recharge-api", title: "Recharge API", type: "link" },
         { path: "/admin/ext-api/ext-recharge-api-setting", title: "Recharge API Settings", type: "link" },

        // { path: "/admin/wallet", title: "Whatsapp API", type: "link" },
        // { path: "/admin/wallet", title: "Text Message API", type: "link" },
      ],
    },

    {
      title: "Commission & Other",
      icon: "widget",
      type: "sub",
      active: false,
      children: [
        // {
        //   path: "/admin/admin-commission",
        //   title: "Admin Commission",
        //   type: "link",
        // },
        {  path: "/admin/commission-other/commission-packages", title: "User Commission Packages", type: "link", },
        { path: "/admin/commission-other/set-user-commission", title: "Set User Commission", type: "link" },
        { path: "/admin/commission-other/amount-range", title: "Amount Range Commission", type: "link" },
        // {
        //   path: "/admin/admin-commissionl",
        //   title: "User Commission",
        //   type: "link",
        // },

        // { path: "/widgets/general", title: "Set Commision", type: "link" },
      ],
    },

    // {
    //   title: "Manager",
    //   icon: "sample-page",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "/widgets/general", title: "SMS API", type: "link" },
    //     { path: "/widgets/general", title: "Whatsapp API", type: "link" },
    //     { path: "/widgets/general", title: "News", type: "link" },
    //     { path: "/widgets/general", title: "Admin Settings", type: "link" },
    //     { path: "/widgets/general", title: "Message Settings", type: "link" },
    //     { path: "/widgets/general", title: "Whatsapp Messages", type: "link" },
    //     { path: "/widgets/general", title: "Android Banner", type: "link" },
    //     { path: "/widgets/general", title: "Toll Free", type: "link" },
    //   ],
    // },

    // {
    //   title: "Recharge",
    //   icon: "sample-page",
    //   type: "sub",
    //   active: false,
    //   children: [
    //     { path: "/admin/recharges", title: "All Recharges", type: "link" },
    //     { path: "/widgets/general", title: "Add Balance", type: "link" },
    //     { path: "/widgets/general", title: "Admin Commision", type: "link" },
    //     { path: "/widgets/general", title: "Change API", type: "link" },
    //     { path: "/widgets/general", title: "Exact Amount", type: "link" },
    //     { path: "/widgets/general", title: "Amount Range", type: "link" },
    //     { path: "/widgets/general", title: "Invalid Amount", type: "link" },
    //     { path: "/widgets/general", title: "User Operator Block", type: "link" },
    //   ],
    // },
    {
      title: "Users",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        { path: "/admin/users/user-list", title: "All Users", type: "link" },
        // { path: "/admin/users/add-user", title: "Add User", type: "link" },
        // { path: "/user/profile", title: "Upgrad User", type: "link" },
        {
          path: "/admin/users/credit-debit",
          title: "Credit / Debit",
          type: "link",
        },
        // { path: "/user/profile", title: "Balance Limit", type: "link" },
        // { path: "/user/profile", title: "Inquiry", type: "link" },
        // { path: "/user/profile", title: "Upload KYC", type: "link" },
        // { path: "/user/profile", title: "Send SMS", type: "link" },
        // { path: "/user/profile", title: "Send Notification", type: "link" },
      ],
    },
    {
      title: "Reports",
      icon: "charts",
      type: "sub",
      active: false,
      children: [
        {
          path: "/admin/report/recharge-report",
          title: "Recharge Report",
          type: "link",
        },
        // { path: "/report/general", title: "Complaints", type: "link" },
        {
          path: "/admin/report/credit-debit-report",
          title: "Credit & Debit Report",
          type: "link",
        },
        
        // {
        //   path: "/admin/report/transaction-report",
        //   title: "Transaction Report",
        //   type: "link",
        // }, 
        
        // {
        //   path: "/admin/report/payment-report",
        //   title: "Payment  Report",
        //   type: "link",
        // },
       
        // {
        //   path: "/admin/report/payment-request-report",
        //   title: "Payment Request",
        //   type: "link",
        // },
        //  { path: "/report/general", title: "Account Report", type: "link" },
        // { path: "/report/general", title: "Operator Report", type: "link" },
        // { path: "/report/general", title: "SMS Report", type: "link" },
      ],
    },
    // { path: "/support-ticket", title: "Support Ticket", icon: "support-tickets", type: "link" },
  ];

  API_USER_MENUITEMS: Menu[] = [
    {
      path: "/api-user/api-user-dashboard",
      title: "Dashboard",
      icon: "home",
      type: "link",
    },
    {
      title: "Developer API",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "Recharge API", type: "link" },
        { path: "/", title: "Balance Check", type: "link" },
        { path: "/", title: "Callback URL", type: "link" },
        { path: "/", title: "Status Check URL", type: "link" },
        { path: "/", title: "API Token", type: "link" },
        { path: "/", title: "Operator Code", type: "link" },
      ],
    },
    {
      title: "Payment",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        // { path: "/api-user/payment/api-user-create-payment-request", title: "Create Payment Request", type: "link" },
        {
          path: "/api-user/payment/api-user-payment-request",
          title: "Payment Request",
          type: "link",
        },
      ],
    },
    {
      title: "Report",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        {
          path: "/api-user/report/api-user-commission-report",
          title: "My Commission",
          type: "link",
        },
        {
          path: "/api-user/report/api-user-commission-earned-report",
          title: "Commission Earned",
          type: "link",
        },
        {
          path: "/api-user/report/api-user-recharge-report",
          title: "Recharge Report",
          type: "link",
        },
        //  { path: "/api-user/report/", title: "Complains", type: "link" },
        {
          path: "/api-user/report/api-user-transaction-report",
          title: "Transaction Report",
          type: "link",
        },
        {
          path: "/api-user/report/api-user-account-report",
          title: "Account Report",
          type: "link",
        },
        {
          path: "/api-user/report/api-user-payment-request-report",
          title: "Payment Request Report",
          type: "link",
        },
      ],
    },
  ];

  SD_USER_MENUITEMS: Menu[] = [
    {
      path: "/sd-user/sd-user-dashboard",
      title: "Dashboard",
      icon: "home",
      type: "link",
    },
    {
      title: "Recharge",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        {
          path: "/sd-user/recharge/sd-user-create-recharge",
          title: "Create Recharge",
          type: "link",
        },
        {
          path: "/sd-user/recharge/sd-user-recharge",
          title: "All Last Recharge",
          type: "link",
        },
      ],
    },

    {
      title: "Commission Settings",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        {
          path: "/sd-user/commission-setting/sd-user-commission-package",
          title: "Commission Package",
          type: "link",
        },
        {
          path: "/sd-user/commission-setting/sd-user-set-commission",
          title: "Set Commission",
          type: "link",
        },
      ],
    },
    {
      title: "User",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        {
          path: "/sd-user/user/sd-user-add-user",
          title: "Add User",
          type: "link",
        },
        { path: "/sd-user/user/sd-user-list", title: "All User", type: "link" },
        {
          path: "/sd-user/user/sd-user-credit-debit",
          title: "Credit Debit",
          type: "link",
        },
      ],
    },
    {
      title: "Payment",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "Payment Request", type: "link" },
        { path: "/", title: "User Payment Request", type: "link" },
        { path: "/", title: "Payment History", type: "link" },
      ],
    },

    {
      title: "Report",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        {
          path: "/sd-user/report/sd-user-commission-report",
          title: "My Commission",
          type: "link",
        },
        {
          path: "/sd-user/report/sd-user-commission-earned-report",
          title: "Commission Earned",
          type: "link",
        },
        {
          path: "/sd-user/report/sd-user-recharge-report",
          title: "Recharge Report",
          type: "link",
        },
        //  { path: "/sd-user/report/", title: "Complains", type: "link" },
        {
          path: "/sd-user/report/sd-user-transaction-report",
          title: "Transaction Report",
          type: "link",
        },
        {
          path: "/sd-user/report/sd-user-account-report",
          title: "Account Report",
          type: "link",
        },
        // { path: "/", title: "Daily Report", type: "link" },
      ],
    },
  ];

  D_USER_MENUITEMS: Menu[] = [
    {
      path: "/admin/admin-dashboard",
      title: "Dashboard",
      icon: "home",
      type: "link",
    },
    {
      title: "Recharge",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [{ path: "/", title: "Create Recharge", type: "link" }],
    },
    {
      title: "User",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "Add User", type: "link" },
        { path: "/", title: "All User", type: "link" },
        { path: "/", title: "Credit Debit", type: "link" },
      ],
    },
    {
      title: "Payment",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "Payment Request", type: "link" },
        { path: "/", title: "User Payment Request", type: "link" },
        { path: "/", title: "Payment History", type: "link" },
      ],
    },
    {
      title: "Report",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "My Commission", type: "link" },
        { path: "/", title: "Commission Earned", type: "link" },
        { path: "/", title: "Recharge Report", type: "link" },
        { path: "/", title: "Complains", type: "link" },
        { path: "/", title: "Transaction Report", type: "link" },
        { path: "/", title: "Account Report", type: "link" },
        { path: "/", title: "Daily Report", type: "link" },
      ],
    },
  ];

  RETAILER_USER_MENUITEMS: Menu[] = [
    {
      path: "/admin/admin-dashboard",
      title: "Dashboard",
      icon: "home",
      type: "link",
    },
    {
      title: "Recharge",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [{ path: "/", title: "Create Recharge", type: "link" }],
    },
    {
      title: "Payment",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "Payment Request", type: "link" },
        { path: "/", title: "Payment History", type: "link" },
      ],
    },
    {
      title: "Report",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "My Commission", type: "link" },
        { path: "/", title: "Commission Earned", type: "link" },
        { path: "/", title: "Recharge Report", type: "link" },
        { path: "/", title: "Complains", type: "link" },
        { path: "/", title: "Transaction Report", type: "link" },
        { path: "/", title: "Account Report", type: "link" },
        { path: "/", title: "Daily Report", type: "link" },
      ],
    },
  ];

  USER_MENUITEMS: Menu[] = [
    {
      path: "/admin/admin-dashboard",
      title: "Dashboard",
      icon: "home",
      type: "link",
    },
    {
      title: "Recharge",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [{ path: "/", title: "Create Recharge", type: "link" }],
    },
    {
      title: "Payment",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "Payment Request", type: "link" },
        { path: "/", title: "Payment History", type: "link" },
      ],
    },
    {
      title: "Report",
      icon: "sample-page",
      type: "sub",
      active: false,
      children: [
        { path: "/", title: "My Commission", type: "link" },
        { path: "/", title: "Commission Earned", type: "link" },
        { path: "/", title: "Recharge Report", type: "link" },
        { path: "/", title: "Complains", type: "link" },
        { path: "/", title: "Transaction Report", type: "link" },
        { path: "/", title: "Account Report", type: "link" },
        { path: "/", title: "Daily Report", type: "link" },
      ],
    },
  ];

  MENUITEMS: Menu[] = this.ADMIN_MENUITEMS;
  //MENUITEMS: Menu[] = this.API_USER_MENUITEMS;
  //MENUITEMS: Menu[] = this.SD_USER_MENUITEMS;
  // MENUITEMS: Menu[] = this.D_USER_MENUITEMS;
  // MENUITEMS: Menu[] = this.RETAILER_USER_MENUITEMS;
  //MENUITEMS: Menu[] = this.USER_MENUITEMS;

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
