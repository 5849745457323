import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";
import { UserService } from "../../shared/services/user.service";
import { CommonService } from "src/app/shared/services/common.service";
import { OperatorService } from "src/app/shared/services/operator.service";
import { ServicesService } from "src/app/shared/services/services.service";
import { UserTypeService } from "src/app/shared/services/user-type.service";
import { ExtRechargeApiService } from "src/app/shared/services/ext-recharge-api.service";
import { TransactionTypeService } from "src/app/shared/services/transactionType.service";
declare var require
const Swal = require('sweetalert2');

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private userService: UserService,
    private authService: AuthService,
    private commonService: CommonService,
    private userTypeService: UserTypeService, private transactionTypeService: TransactionTypeService, private extRechargeApiService: ExtRechargeApiService, private operatorService: OperatorService, private servicesService: ServicesService, 
    
  ) {
    this.loginForm = this.fb.group({
      user_name: ["", [Validators.required]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  login_old() {
    if (
      this.loginForm.value["email"] == "Test@gmail.com" &&
      this.loginForm.value["password"] == "test123"
    ) {
      let user = {
        email: "Test@gmail.com",
        password: "test123",
        name: "test user",
      };
      localStorage.setItem("user", JSON.stringify(user));
      this.router.navigate(["/admin/admin-dashboard"]);
    }
  }

  login() {
    this.authService
      .login(
        this.loginForm.value["user_name"],
        this.loginForm.value["password"]
      )
      .toPromise()
      .then(async (res: any) => {
        if (res.status) {
 
          await localStorage.setItem("token", res.token);

        await  this.commonService.loadUserMasterData().then((res) => {
           this.userTypeService.userTypeList = res.result.userTypes
           this.servicesService.servicesList = res.result.services
           this.operatorService.operatorList = res.result.operator
           this.extRechargeApiService.extRechargeApiList = res.result.extRechargeApi
         this.transactionTypeService.transactionTypeList = res.result.transType

          })
          .catch((error) => {
          });
         
          await  this.userService.getLoggedInUserDetails().then((res) => {
            this.userService.loggedInUser = res.result;
          })
          .catch((error) => {
          });


          let user = {
            email: this.userService.loggedInUser.email,
            password: "pass",
            name: this.userService.loggedInUser.name,
          }; 
          localStorage.setItem("user", JSON.stringify(user));
 


          Swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'Login Successfull.',
            showConfirmButton: false,
            timer: 1500
          })
          switch (this.userService.loggedInUser.type_id) {
            case 1:
              this.router.navigate(["/admin/admin-dashboard"]);
              break;

            default:
              break;
          }
        }
      })
      .catch((error) => {

        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: error.error.message,
          showConfirmButton: false,
          timer: 1500
        })
      });
  }

  showPassword() {
    this.show = !this.show;
  }
}
