export class ExtRechargeApi {
    id:  number
    name: string;
    code: string;
    recharge_url: string
    api_token: string;
    balance_check_url : string
     param1 : string
     param2 : string
     param3 : string
     param4 : string
     param5 : string
     param6 : string
     param7 : string
     operator_balance: any;
     is_active: number
}

 